import * as client_hooks from '../../../src/app/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [2,3];

export const dictionary = {
		"/(app)": [~7,[2]],
		"/callback": [16],
		"/(app)/companies": [~8,[2]],
		"/(app)/companies/[id]": [~9,[2,3]],
		"/(app)/companies/[id]/advanced-analytics": [~10,[2,3]],
		"/(app)/companies/[id]/forecasts": [~11,[2,3]],
		"/(app)/industries/[slug]": [~12,[2,4]],
		"/(app)/industries/[slug]/alignment": [13,[2,4]],
		"/login": [~17],
		"/logout": [18],
		"/(app)/search": [14,[2]],
		"/showcase/charts": [~19,[5,6]],
		"/showcase/charts/alignment": [20,[5,6]],
		"/showcase/charts/area": [21,[5,6]],
		"/showcase/charts/charts-edge": [22,[5,6]],
		"/showcase/charts/contributions": [23,[5,6]],
		"/showcase/charts/emissions": [24,[5,6]],
		"/showcase/charts/stack": [25,[5,6]],
		"/showcase/charts/switchable": [26,[5,6]],
		"/showcase/charts/tooltip": [27,[5,6]],
		"/showcase/chat": [28,[5]],
		"/showcase/colors": [29,[5]],
		"/showcase/components": [30,[5]],
		"/showcase/icons": [31,[5]],
		"/showcase/numbers": [32,[5]],
		"/showcase/scenarios": [33,[5]],
		"/showcase/signup": [34,[5]],
		"/showcase/typography": [35,[5]],
		"/trial-ended": [36],
		"/(app)/whats-new": [~15,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';